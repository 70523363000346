import "pixi-filters";
import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";

const SportsPage = lazy(() => import("./pages/Sports"));
// const MarryPage = lazy(() => import("./pages/Marry"));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<SportsPage />} />
        <Route path="/sports" element={<SportsPage />} />
        {/* <Route path="/marry" element={<MarryPage />} /> */}
      </Routes>
    </Suspense>
  );
}

export default App;
